import { forwardRef, Input, InputProps } from "@chakra-ui/react"
import React, { useState } from "react"
import { useDebounce } from "react-use"

interface Props {
  debounce?: number
  onChange?: (value: string) => void
}

const DebouncedInput = forwardRef<
  Omit<InputProps, "onChange"> & Props,
  "input"
>((props, ref) => {
  const { debounce = 200, onChange, ...rest } = props
  const [value, setValue] = useState("")

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value
    setValue(newValue)
  }

  useDebounce(
    () => {
      onChange?.(value)
    },
    debounce,
    [value]
  )

  return <Input ref={ref} {...rest} onChange={handleChange} />
})

export default DebouncedInput
