import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import loadable from "@loadable/component"
import Fuse from "fuse.js"
import { navigate } from "gatsby"
import React, { useCallback, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { FiSearch, FiX } from "react-icons/fi"
import { scroller } from "react-scroll"
import { useUpdateEffect } from "react-use"
import DebouncedInput from "~/components/inputs/DebouncedInput"
import RoundedPagination from "~/components/RoundedPagination"
import ScrollView from "~/components/ScrollView"
import { usePagination } from "~/hooks/usePagination"
import { interactionResponse } from "~/services/webvitals/defer"

let scrollTimeoutId: number

export interface RemoteStoreApi {
  id: EntityId
  name: string
  slug: string
  phone: string
  latitude: number
  longitude: number
  address1: string
  address2: string
  city: string
  region: string
  postCode: string
  country: string
  remotePrintServiceId: EntityId
  printServiceId: EntityId
}

interface Props {
  pageContext: {
    stores: RemoteStoreApi[]
  }
}

const StoresMap = loadable(() => import("./StoresMap"))

const Stores: React.FC<Props> = ({ pageContext }) => {
  const { stores } = pageContext
  const allStores = useMemo(() => convertItems(stores), [stores])

  const { t } = useTranslation()

  const fuse = useMemo(
    () =>
      new Fuse(stores, {
        keys: [
          { name: "postCode", weight: 5 }, // Priority to post code
          { name: "city", weight: 4 },
          { name: "address1", weight: 3 },
          { name: "address2", weight: 3 },
          { name: "name", weight: 2 },
          { name: "region", weight: 1 },
          { name: "country", weight: 1 },
        ],
        threshold: 0.3, // 0 to 1 - Higher is fuzzier - Almost looking for the perfect match.
      }),
    [stores]
  )

  const [currentSearch, setCurrentSearch] = useState("")

  const searchInputRef = useRef<HTMLInputElement>(null)
  const [results, setResults] = useState(allStores)

  const {
    page,
    pages,
    currentPage,
    gotoPage,
    nextPage,
    previousPage,
    totalPages,
  } = usePagination<Fuse.FuseResult<RemoteStoreApi>>(results, {
    itemsPerPages: 10,
    orderBy: {
      id: "desc",
    },
  })

  const [selectedStore, setSelectedStore] = useState(page?.[0].item)

  const handleSearchChange = (value: string) => {
    setCurrentSearch(value)
  }

  useUpdateEffect(() => {
    if (!currentSearch) {
      setResults(allStores)
      return
    }

    const search = fuse.search(currentSearch)
    setResults(search)
  }, [fuse, currentSearch, stores])

  useUpdateEffect(() => {
    gotoPage(0)

    if (results[0]) {
      setSelectedStore(results[0].item)
    }
  }, [results])

  const scrollToStore = (storeId: number) => {
    if (scrollTimeoutId) {
      clearTimeout(scrollTimeoutId)
    }
    scrollTimeoutId = window.setTimeout(() => {
      scroller.scrollTo(`store-${storeId}`, {
        containerId: "stores-list",
        duration: 500,
        smooth: true,
      })
    }, 500)
  }

  const onClickStoreRow = async (store: RemoteStoreApi) => {
    await interactionResponse()
    setSelectedStore(store)
    scrollToStore(Number(store.id))
  }

  const onClickMarker = useCallback(
    async (storeId: number) => {
      await interactionResponse()

      let activatedStore = null
      const relatedPageIndex = pages.findIndex(page => {
        activatedStore = page.find(result => +result.item.id === storeId)?.item
        return activatedStore
      })

      if (activatedStore) {
        gotoPage(relatedPageIndex)
        setSelectedStore(activatedStore)
        scrollToStore(storeId)
      }
    },
    [gotoPage, pages]
  )

  const onGotoPage = async (pageNumber: number) => {
    await interactionResponse()
    gotoPage(pageNumber)
    const newStore = pages?.[pageNumber][0].item
    setSelectedStore(newStore)
    scrollToStore(Number(newStore.id))
  }

  const onNextPage = async () => {
    await interactionResponse()
    nextPage()
    const newStore = pages?.[currentPage + 1][0].item
    setSelectedStore(newStore)
    scrollToStore(Number(newStore.id))
  }

  const onPreviousPage = async () => {
    await interactionResponse()
    previousPage()
    const newStore = pages?.[currentPage - 1][0].item
    setSelectedStore(newStore)
    scrollToStore(Number(newStore.id))
  }

  const clearSearch = async () => {
    await interactionResponse()
    setCurrentSearch("")
    if (searchInputRef.current) {
      searchInputRef.current.value = ""
      searchInputRef.current.focus()
    }
  }

  return (
    <>
      <Heading as="h1" size="lg" mb={3}>
        {t("templates.layout.stores.Stores.Title")}
      </Heading>
      <Text>{t("templates.layout.stores.Stores.Subtitle")}</Text>

      <Flex
        flexDir={{ base: "column", md: "row" }}
        justifyContent="stretch"
        mt={8}
        minH={610}
      >
        <Box width={{ base: "100%", md: "50%" }} pr={{ base: 0, md: 6 }}>
          <InputGroup>
            <InputLeftElement h="100%">
              <Button
                h="1.75rem"
                size="sm"
                cursor={currentSearch ? "pointer" : "default"}
                onClick={clearSearch}
                id="stores-clear-search"
                variant="ghost"
                _hover={{
                  background: "transparent",
                }}
                _active={{
                  background: "transparent",
                }}
              >
                <Icon
                  as={currentSearch ? FiX : FiSearch}
                  color={currentSearch ? "gray.600" : "gray.300"}
                />
              </Button>
            </InputLeftElement>
            <Input
              as={DebouncedInput}
              ref={searchInputRef}
              debounce={500}
              id="store-search-input"
              // @ts-ignore ignoring this type error for now
              onChange={handleSearchChange}
              placeholder={t("templates.layout.stores.Stores.Search")}
            />
          </InputGroup>

          <Text
            ml={2}
            mt={1}
            mb={3}
            fontStyle="italic"
            fontSize="sm"
            color="gray.400"
          >
            {t("templates.layout.stores.Stores.Results", {
              count: results.length,
            })}
          </Text>

          <ScrollView maxH={480} id="stores-list">
            <Stack spacing={3} p={1} pt={0}>
              {page?.map(result => {
                const { item } = result
                const { id } = item

                return (
                  <StoreRow
                    key={id}
                    store={item}
                    isActive={selectedStore.id === id ? true : false}
                    onClickStoreRow={() => onClickStoreRow(item)}
                  />
                )
              })}
            </Stack>
          </ScrollView>

          {totalPages > 1 && (
            <Flex justifyContent="center" mt={3}>
              <RoundedPagination
                id="stores"
                currentPage={currentPage}
                nextPage={onNextPage}
                previousPage={onPreviousPage}
                gotoPage={onGotoPage}
                totalPages={totalPages}
              />
            </Flex>
          )}
        </Box>

        <Box
          width={{ base: "100%", md: "50%" }}
          h={{ base: 500, md: "auto" }}
          mt={{ base: 5, md: 0 }}
        >
          <Box
            overflow="hidden"
            boxShadow="base"
            borderRadius={4}
            width="100%"
            height="90%"
          >
            <StoresMap
              selectedStore={selectedStore}
              stores={results.map(result => result.item)}
              onClickMarker={onClickMarker}
            />
          </Box>
        </Box>
      </Flex>
    </>
  )
}

export default Stores

interface StoreRowProps {
  store: RemoteStoreApi
  isActive: boolean
  onClickStoreRow: (storeId: EntityId) => void
}

const StoreRow: React.FC<StoreRowProps> = ({
  store,
  isActive = false,
  onClickStoreRow,
}) => {
  const { id, name, slug, city, address1, address2, postCode, country, phone } =
    store
  const { t } = useTranslation()

  return (
    <Box
      boxShadow="base"
      padding={4}
      borderRadius={4}
      fontSize="sm"
      borderStyle="solid"
      borderWidth={1}
      borderColor={isActive ? "primary.500" : "transparent"}
      background={isActive ? "primary.50" : "transparent"}
      cursor="pointer"
      _hover={{
        borderColor: "primary.500",
      }}
      name={`store-${store.id}`}
      id={`stores-row-${id}`}
      onClick={e => {
        e.stopPropagation()
        onClickStoreRow(id)
      }}
    >
      <Heading as="h3" fontSize="md">
        {name}
      </Heading>
      <Text>
        {address1}
        {address2 && ` ${address2}`}
      </Text>
      <Text>
        {city}, {postCode}
      </Text>
      <Text>{country}</Text>
      <Flex color="primary.500" mt={3} fontWeight="bold">
        <Box _hover={{ textDecoration: "underline" }}>
          <Link
            id={`store-${id}-details`}
            href={`/stores/${slug}/`}
            onClick={async e => {
              e.preventDefault()
              await interactionResponse()
              navigate(`/stores/${slug}/`)
            }}
          >
            {t("templates.layout.stores.StoreRow.Link")}
          </Link>
        </Box>
        {phone && (
          <Link id={`store-${id}-phone`} ml={8} href={`tel:${phone}`}>
            {t("templates.layout.stores.StoreRow.Phone")}
          </Link>
        )}
      </Flex>
    </Box>
  )
}

const convertItems = (stores: RemoteStoreApi[]) => {
  return stores.map(store => ({
    item: store,
    refIndex: Number(store.id),
  }))
}
